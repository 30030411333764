import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EventStatus } from 'src/app/components/event-list/event-list.model';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable'
import { DownloadFileOrPrint } from '../common-model/common.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  eventStatus = EventStatus;

   getFormattedDate(date: Date): string {
    const datePipe = new DatePipe('en-US'); // or your preferred locale
    // return datePipe.transform(date, 'yyyy-MM-dd') || '';
    return datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss.SSS') || '';
    // return datePipe.transform(date, 'yyyy-MM-ddT00:00:00.000') || '';
    // 'EEE MMM dd yyyy HH:mm:ss zZZZ'
  }
  
 
  getStartDateUtc(date: Date):string{
    date?.setHours(0);
    date?.setMinutes(0);
    date?.setSeconds(0);
    date?.setMilliseconds(0);

    var dt = new Date(date.toUTCString());
    return dt.toISOString();
  }

  getEndDateUtc(date: Date):string{
    date?.setHours(23);
    date?.setMinutes(59);
    date?.setSeconds(59);
    date?.setMilliseconds(999);

    var dt = new Date(date?.toUTCString());
    return dt?.toISOString();
  }

  getTodayFormattedDate(): {startDate:string, endDate:string } {
    const today = new Date();

    return {
      startDate: this.getStartDateUtc(today),
      endDate: this.getEndDateUtc(today)
    };
  }

  
   getTomorrowFormattedDate(): {startDate:string ,endDate:string } {
    const datePipe = new DatePipe('en-US'); // or your preferred locale
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    // return datePipe.transform(tomorrow, 'yyyy-MM-dd') || '';
    return {
      startDate: this.getStartDateUtc(tomorrow),
      endDate: this.getEndDateUtc(tomorrow)
    };
  }

  getYesterdayFormattedDate(): { startDate: string, endDate: string } {
    const datePipe = new DatePipe('en-US'); // or your preferred locale
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return {
      startDate: this.getStartDateUtc(yesterday),
      endDate: this.getEndDateUtc(yesterday)
    };
  }

  getLast7DaysFormattedDates(convertToUtc:boolean=true): { startDate: string, endDate: string } {
    const datePipe = new DatePipe('en-US'); // or your preferred locale
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 6); // Subtract 6 to get the start date for the last 7 days
    const endDate = new Date(today);
    
    return {
      startDate: convertToUtc ? this.getStartDateUtc(startDate):this.formatToDate(startDate),
      endDate: convertToUtc ?  this.getEndDateUtc(endDate): this.formatToDate(endDate)
    };
  }

  formatToDate(date: Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe?.transform(date, 'yyyy-MM-dd');
  }

  getLast30DaysFormattedDates(convertToUtc:boolean=true): { startDate: string, endDate: string } {
    const datePipe = new DatePipe('en-US'); // or your preferred locale
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 29); // Subtract 29 to get the start date for the last 30 days
    const endDate = new Date(today);
  
    return {
      startDate: convertToUtc ? this.getStartDateUtc(startDate):this.formatToDate(startDate),
      endDate: convertToUtc ?  this.getEndDateUtc(endDate): this.formatToDate(endDate)
    };
  };

  getNext7DaysFormattedDates(): { startDate: string, endDate: string } {
    const datePipe = new DatePipe('en-US'); // or your preferred locale
    const today = new Date();
    const startDate = new Date(today);
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + 6); // Add 6 to get the end date for the next 7 days
  
    return {
      startDate: this.getStartDateUtc(startDate),
      endDate: this.getEndDateUtc(endDate)
    };
  };

  getNext30DaysFormattedDates(): { startDate: string, endDate: string } {
    const datePipe = new DatePipe('en-US'); // or your preferred locale
    const today = new Date();
    const startDate = new Date(today);
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + 29); // Add 29 to get the end date for the next 30 days
  
    return {
      startDate: this.getStartDateUtc(startDate),
      endDate: this.getEndDateUtc(endDate)
    };
  }
  
  
  getThisWeekendFormattedDates(): { startDate: string, endDate: string } {
    const today = new Date();
    // Calculate next Saturday
    const saturday = new Date(today);
    saturday.setDate(today.getDate() + ((6 - today.getDay() + 7) % 7));
    // Calculate next Sunday
    const sunday = new Date(today);
    sunday.setDate(today.getDate() + ((7 - today.getDay() + 7) % 7));
    // return {
    //   saturday: this.getFormattedDate(saturday),
    //   sunday: this.getFormattedDate(sunday)
    // };
    return {
      startDate: this.getStartDateUtc(saturday),
      endDate: this.getEndDateUtc(sunday)
    };
  }
  
  getThisWeekFormattedDates(): { startDate: string, endDate: string } {
    const today = new Date();
    // Calculate the start of the week (Sunday)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));
    // Calculate the end of the week (Saturday)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    return {
      startDate: this.getStartDateUtc(startOfWeek),
      endDate: this.getEndDateUtc(endOfWeek)
    };
  }
  
  getNextWeekFormattedDates(): { startDate: string, endDate: string } {
    const today = new Date();
    // Calculate the start of next week (Sunday)
    const startOfNextWeek = new Date(today);
    startOfNextWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? 1 : 8));
    // Calculate the end of next week (Saturday)
    const endOfNextWeek = new Date(startOfNextWeek);
    endOfNextWeek.setDate(startOfNextWeek.getDate() + 6);
    return {
      startDate: this.getStartDateUtc(startOfNextWeek),
      endDate: this.getEndDateUtc(endOfNextWeek)
    };
  }
  
  getThisMonthFormattedDates(): { startDate: string, endDate: string } {
    const today = new Date();
    // Calculate the start of the month
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    // Calculate the end of the month
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return {
      startDate: this.getStartDateUtc(startOfMonth),
      endDate: this.getEndDateUtc(endOfMonth)
    };
  }

  getPastMonthsDateRange(months: number): { startDate: string, endDate: string } {
    const today = new Date();

    // Calculate the start date for the specified number of months ago
    const startOfPastMonths = new Date(today);
    startOfPastMonths.setMonth(today.getMonth() - months);

    // Calculate the end date as today
    const endOfPastMonths = today;

    return {
      startDate: this.getStartDateUtc(startOfPastMonths),
      endDate: this.getEndDateUtc(endOfPastMonths)
    };
  }

  convertLocalToUtcDateTime(localDate: string) {
    if (localDate) {
      const datePipe = new DatePipe('en-US');
      const localDateObj = new Date(datePipe.transform(localDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ', 'en-US'));
      // If your local date is not in the 'yyyy-MM-ddTHH:mm:ss' format, adjust it accordingly.
      return localDateObj.toISOString();
    }
    return '';
  }


  convertLocalDateToUtc(localDate) {
    try {
      // Assuming localDate is a JavaScript Date object representing a local date
      const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
      return utcDate.toISOString();
    } catch (error) {
      console.error(`Error converting local date to UTC: ${error}`);
      return null;
    }
  }


  convertUtcDateToEndOfDay(utcDate: string): string | null {
    try {
      const date = new Date(utcDate);
      date.setDate(date.getDate() + 1);
      date.setMilliseconds(date.getMilliseconds() - 1);
  
      // Use toISOString to ensure the output is in UTC
      return date.toISOString();
    } catch (error) {
      console.error(`Error converting UTC date: ${error}`);
      return null;
    }
  }

  convertToMidnightUTC(utcDateString) {
    try {
      const originalDate = new Date(utcDateString);
      // Set hours, minutes, seconds, and milliseconds to zero
      originalDate.setUTCHours(0, 0, 0, 0);
      // Get the result as a UTC date string
      const convertedDate = originalDate.toISOString();
      return convertedDate;
    } catch (error) {
      console.error(`Error converting to midnight UTC: ${error}`);
      return null;
    }
  }

  convertToEndOfDayUTC(utcDateString) {
    try {
      const originalDate = new Date(utcDateString);
      // Set hours, minutes, and seconds to the end of the day
      originalDate.setUTCHours(23, 59, 59, 999);
      // Get the result as a UTC date string
      const convertedDate = originalDate.toISOString();
      return convertedDate;
    } catch (error) {
      console.error(`Error converting to end of day UTC: ${error}`);
      return null;
    }
  }

  convetUtcTolocalDateTime(date:string){
    if(date){
    const localDate = new Date(date + 'Z'); // Append 'Z' to indicate UTC time;
    const datePipe = new DatePipe('en-US');
    return (datePipe.transform(localDate, 'MM/dd/yyyy h:mm a', 'en-US'))?.toLowerCase();
    }
    return ''
  }

  formatDate(inputDateString: string): string {
    // '5/17/2023 4:28 pm';  Output: May 17, 2023 4:28 PM
    try {
      const originalDate = new Date(inputDateString);
      if (isNaN(originalDate.getTime())) {
        // throw new Error('Invalid date');
      }
  
      const formattedDate = originalDate.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // hour12: true,
      });
  
      return formattedDate;
    } catch (error) {
      console.error('Error formatting date:', error.message);
      return 'Invalid Date';
    }
  }

   addCurrentTime(originalDateString: string): string {
    const currentDate = new Date();
    const currentHours = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    const currentSeconds = currentDate.getSeconds();
    const currentMilliseconds = currentDate.getMilliseconds();
  
    const newDate = new Date(originalDateString);
    newDate.setHours(currentHours, currentMinutes, currentSeconds, currentMilliseconds);
  
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(newDate, 'yyyy-MM-ddTHH:mm:ss.SSS') || '';
    return formattedDate;
  }


  // status Color as per event status enum  start//

  getStatusColor(eventStatusId:number , isTextAndBackground? :boolean){
    switch(eventStatusId){
      case(EventStatus?.Approved):
      if(isTextAndBackground){
        return 'text-theme-color-white bg-theme-color-success';
      }
      return 'text-theme-color-success';

      case(EventStatus?.Cancelled):
      if(isTextAndBackground){
        return 'text-theme-color-dark bg-theme-color-warning-surface';
      }
      return 'text-theme-color-dark';

      case(EventStatus?.Completed):
      if(isTextAndBackground){
        return 'text-theme-color-dark bg-theme-color-success-surface';
      }
      return 'text-theme-color-success';

      case(EventStatus?.Expired):
      if(isTextAndBackground){
        return 'text-theme-color-white bg-theme-color-success';
      }
      return ;

      case(EventStatus?.PartiallyRejected ):
      if(isTextAndBackground){
        return 'text-theme-color-white bg-theme-color-Warning';
      }
      return 'text-theme-color-Warning';

      case(EventStatus?.Pending):
      if(isTextAndBackground){
        return 'text-theme-color-white bg-theme-color-grey';
      }
      return 'text-theme-color-sapphire-50';

      case(EventStatus?.Rejected):
      if(isTextAndBackground){
        return 'text-theme-color-dark bg-theme-color-error-surface';
      }
      return 'text-theme-color-error';

      case(EventStatus?.PayPalPending):
      if(isTextAndBackground){
        return 'text-theme-color-white bg-theme-color-sapphire-80';
      }
      return 'text-theme-color-dark';

      case(EventStatus?.PayPalRejected):
      if(isTextAndBackground){
        return 'text-theme-color-dark bg-theme-color-warning-surface';
      }
      return 'text-theme-color-accent';

      case(EventStatus?.Suspended):
      return ;
    }
  }
  // status Color as per event status enum  end//


  //#region dowonload pdf or print
  generatePdfAndPrint(data: any[], columns: any[], fileName: string ,isPrintOrPdfDownload=DownloadFileOrPrint?.Pdf): void {
    const doc = new jsPDF({orientation:"landscape"});
  
    const headData = [columns?.map(column => column?.title)];
    const bodyData = data?.map(row => columns?.map(column => row[column?.name]));
    
    autoTable(doc, {
      head: headData,
      body: bodyData,
    })

    switch(isPrintOrPdfDownload){
      case(DownloadFileOrPrint?.Pdf):  
        doc.save(`${fileName}.pdf`);
        break;
      case(DownloadFileOrPrint?.Print):  
       // Get the data URI of the PDF
      const dataUri = doc.output("datauristring");

      // Open the PDF in a new window for printing
      const printWindow = window?.open();
      if (printWindow) {
        printWindow?.document?.write(`<iframe width="100%" height="100%" src="${dataUri}" download="${fileName}.pdf" title="${fileName}.pdf"></iframe>`);
        printWindow?.document?.close();
        // printWindow?.print(); // Open the print dialog
      } else {
        // Handle popup blocked or failed to open
        alert('Popup blocked! Please enable popups for this site and try again.');
      }
      // const printWindow = window?.open(dataUri, '_blank');
      // if (printWindow) {
      //   printWindow?.print(); // Open the print dialog
      // } else {
      //   // Handle popup blocked or failed to open
      //   alert('Popup blocked! Please enable popups for this site and try again.');
      // }
      break;
    }
  }
  //#endregion dowonload pdf or print

  //#region csv download start 

  generateCsvFileAndDownload(data: any[], columns: any[], fileName: string): void {
    const csvContent = this.generateCsv(data, columns);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');

    link.href = window?.URL?.createObjectURL(blob);
    link.download = `${fileName}.csv`;

    // Trigger the download
    link?.click();

    // Clean up
    window.URL.revokeObjectURL(link?.href);
  }

  private generateCsv(data: any[], columns: any[]): string {
    // Create the CSV header
    const header = columns?.map((column) => column?.title)?.join(',');

    // Create the CSV rows
    const rows = data.map((row) =>
      columns.map((column) => this.escapeCsvValue(row[column?.name]))?.join(',')
    );

    // Combine header and rows
    return [header, ...rows].join('\n');
  }

  private escapeCsvValue(value: any): string {
    // Add double quotes if the value contains commas
    const stringValue = value !== null && value !== undefined ? String(value) : '';
    return stringValue?.includes(',') ? `"${stringValue}"` : stringValue;
  }
  //#region csv download end


  //#region  get dynamically sr number start
  calculateRowIndex(pageNumber, rowIndex, pageSize) {
    return (pageNumber - 1) * pageSize + rowIndex;
  }
  //#region  get dynamically sr number end

}