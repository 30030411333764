// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDUGb2EhVKvjBruis2KwFBbqPSVNjZ4kJI",
    authDomain: "ela---prestage.firebaseapp.com",
    projectId: "ela---prestage",
    storageBucket: "ela---prestage.appspot.com",
    messagingSenderId: "884481466376",
    appId: "1:884481466376:web:2ac76dc7f839c2c8e7f1bd",
    measurementId: "G-S7E6CW0FR6",
    vapidKey: "BPi-_a6ulE4Nu3fJ51lLi8AfXICdMy8zCfYSoCikK8BJDRgurEOZn-hPKqjJIX4zRfAMRrPx6elRc-p-8dNshHo"
},
};

export const baseUrl = 'https://apiprestageela.12skiestech.com/api/'; //  publish api 


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
